<template>
  <div style="font-size: 12pt" class="alert fade show alert-dismissible fade show" v-if="alert.message" :class="'alert-' + alert.color" role="alert">
    {{ alert.message }}
    <ul v-if="alert.errors">
      <!-- <li v-for="(value, key) in errors">
          {{ value[0] }}
      </li> -->
    </ul>
    <button type="button" class="btn-close" aria-label="Close" @click="alert.resetState"></button>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { onBeforeUnmount, watch } from 'vue'
import {alertStore} from "@/store/alert";
const alert = alertStore();
const route = useRoute();
onBeforeUnmount(() => {
  alert.resetState();
});

watch(() => route.params.id, () => {
  alert.resetState();
})

</script>

<style scoped>

</style>