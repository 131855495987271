<template>
  <Layout>
    <div class="container-fluid pt-5" style="margin-bottom: 120px">
      <companyName v-bind:name="company.company.name"/>
      <btn-back/>
      <div class="title mb-2">
        Owners and Family Members
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <BootStrapAlert/>
        </div>

        <div class="col-md-12">
          <div class="select-provider mt-4" v-if="user.owners.length === 1">
            Please select <span class="text-danger">{{user.owners[0].owner}},</span> if they are paid W-2 wages
          </div>
          <div class="select-provider mt-4" v-else>
            Please select
            <span class="text-danger"
                  v-for="(item, i) in user.owners.slice(0, user.owners.length - 1)" v-bind:key="i">
              {{item.owner}}<span v-if="i !== user.owners.length - 2">, </span> </span>
            and <span class="text-danger">{{user.owners[user.owners.length - 1].owner}},</span>
            if they are paid W-2 wages
          </div>
          <div class="select-provider mt-2">
            AND
          </div>
          <div class="select-provider mt-2">
            Any family members related to the above people including in-laws
          </div>
          <div class="font_400_12_18 mb-2 mt-2">
            Search Member Name
          </div>
          <form @submit.prevent>
            <input
                @keyup="searchName"
                @keyup.enter.prevent
                placeholder="Search" class="form-control search-member" value="" type="search"/>
          </form>
        </div>
      </div>

      <form id="familyOwners" class="row mt-2">
        <div class="col-md-12 col-lg-12 mt-1">
          <div class="list-panel">
            <div class="header-list">
              <input @click="status.OnClickedCheckAll(1)" id="list-1"
                     :checked="checkedAll"
                     class="checkbox-custom" type="checkbox">
              <label for="list-1"
                     class="checkbox-custom-label mb-0">Select All</label>
            </div>

            <div class="panel-body">
              <div class="row">
                <div class="col-md-4" v-for="(item, index) in status.familyOwners.list1" :key="index">
                  <div class="list-item-1">
                    <input :checked="item.checked" :id="`list1${index}`"
                           @click="OnItemRadioBtnClicked(index)"
                           class="checkbox-custom"  type="checkbox"/>
                    <label :for="`list1${index}`" class="checkbox-custom-label mb-0">{{ item.name }} {{item.checked}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="mt-3 text-center">
        <button
            @click="OnSaveBtnClickedFamilyMembers"
            type="button"
            class="submit">
          Save
        </button>
      </div>
    </div>
  </Layout>
</template>
<script setup>
import Layout from "@/layouts/main";
import BootStrapAlert from "@/components/utils/AlertPinia";
import btnBack from "@/components/btn-back/btnBack";
import companyName from "@/components/company-name/company-name";
import {companyStore} from "@/store/company";
import {statusStore} from "@/store/status";
import {userStore} from "../../../../store/user";
import {computed, inject} from "vue";
import loanApi from "../../../../apis/loan-api";
import {alertStore} from "../../../../store/alert";
import {useRoute} from "vue-router";
const changeStatus = inject('changeStatus')
const company = companyStore();
const status = statusStore();
const user = userStore();
const router = useRoute()
const appID = router.params.id
const alert = alertStore()


const checkedAll = computed(() => {
  return status.familyOwners.list1.filter(item => !item.checked).length === 0
})

function OnItemRadioBtnClicked(pos){
  status.familyOwners.list1[pos].checked = !status.familyOwners.list1[pos].checked
}

async function OnSaveBtnClickedFamilyMembers() {
  changeStatus(true)
  await loanApi.saveFamilyMembers(appID, status.familyOwners.list1)
      .then((response) => {
        const {status, message} = response;
        if (status === "success") {
          alert.setAlert({message: message, errors: null, color: 'success'})
        } else
          alert.setAlert({message: message, errors: null, color: 'danger'})
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        changeStatus(false)
      });
}


user.$subscribe((mutation) =>{
  mutation.type
  mutation.storeId
})
function searchName() {}
</script>

<style scoped lang="scss" src="./ownersfamilymembers.scss"></style>